import React, { useEffect, useState } from 'react';

const OneTrustButton = () => {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (typeof document === 'undefined') return undefined;
    const ot = document.getElementById('onetrust-pc-sdk');
    if (!ot) return undefined;

    const observer = new MutationObserver(mutations => {
      mutations.forEach(mutation => {
        if (
          mutation.type === 'attributes' &&
          (mutation.target as HTMLDivElement).attributes.style.value.includes('display: none')
        ) {
          setIsOpen(false);
        }
      });
    });

    if (ot) {
      observer.observe(ot, { attributes: true });
    }

    return () => observer.disconnect();
  }, []);

  // Return nothing if oneTrust is not mounted
  if (typeof window === 'undefined') return null;
  if (!window.OneTrust) return null;

  const handleOpen = () => {
    setIsOpen(true);
    window.OneTrust.ToggleInfoDisplay();
  };

  const handleClose = () => {
    setIsOpen(false);
    window.OneTrust.Close();
  };

  return (
    <div
      id="ot-sdk-btn-floating"
      className="ot-floating-button"
      style={{ zIndex: 9999999999 }}
      // style={{ position: 'absolute', top: '20px', right: '20px' }}
      data-title="Your Privacy Choices - Manage Cookies"
    >
      {/* Front Button (Open) */}
      <div
        style={{
          transform: !isOpen ? 'rotateY(0deg)' : 'rotateY(-180deg)',
        }}
        className="ot-floating-button__front custom-persistent-icon"
      >
        <button
          type="button"
          className="ot-floating-button__open"
          aria-label="Open Preferences"
          aria-hidden={isOpen}
          style={{ display: isOpen ? 'none' : 'block' }}
          onClick={handleOpen}
        />
      </div>

      {/* Back Button (Close) */}
      <div
        style={{
          transform: isOpen ? 'rotateY(0deg)' : 'rotateY(-180deg)',
        }}
        className="ot-floating-button__back custom-persistent-icon"
      >
        <button
          type="button"
          className="ot-floating-button__close"
          aria-label="Close Preferences"
          aria-hidden={!isOpen}
          style={{
            display: isOpen ? 'block' : 'none',
          }}
          onClick={handleClose}
        >
          <svg
            role="presentation"
            tabIndex={-1}
            viewBox="0 0 24 24"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
              <g
                id="Banner_02"
                className="ot-floating-button__svg-fill"
                transform="translate(-318.000000, -725.000000)"
                fill="#ffffff"
                fillRule="nonzero"
              >
                <g id="Group-2" transform="translate(305.000000, 712.000000)">
                  <g id="icon/16px/white/close">
                    <polygon
                      id="Line1"
                      points="13.3333333 14.9176256 35.0823744 36.6666667 36.6666667 35.0823744 14.9176256 13.3333333"
                    />{' '}
                    <polygon
                      id="Line2"
                      transform="translate(25.000000, 25.000000) scale(-1, 1) translate(-25.000000, -25.000000)"
                      points="13.3333333 14.9176256 35.0823744 36.6666667 36.6666667 35.0823744 14.9176256 13.3333333"
                    />{' '}
                  </g>
                </g>
              </g>
            </g>
          </svg>
        </button>
      </div>
    </div>
  );
};

export default OneTrustButton;
