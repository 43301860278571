import { create } from 'zustand';

export interface PageGlobalContext {
  species?: 'dog' | 'cat';
  brand?: string;
}

interface PageStore {
  page: PageGlobalContext;
  setPage: (page: PageGlobalContext) => void;
}

export const usePageStore = create<PageStore>()(set => ({
  page: {},
  setPage: context => set({ page: context }),
}));
