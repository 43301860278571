import { WrapPageElementBrowserArgs } from 'gatsby';
import * as React from 'react';
import ReactQueryWrapper from 'src/components/ReactQueryWrapper';
import LaunchDarklyFlags from './components/launch_darkly/LaunchDarklyFlags';
import OneTrustButton from './components/oneTrust/OneTrustButton';
import { usePageStore } from './stores/page';
import { toMachineName } from './utils/helpers';

// eslint-disable-next-line react/function-component-definition
export const WrapRootWithProvider = ({ element }: { element: React.ReactNode }) => {
  // Instantiating store in `wrapRootElement` handler ensures:
  //  - there is fresh store for each SSR page
  //  - it will be called only once in browser, when React mounts
  return <ReactQueryWrapper>{element}</ReactQueryWrapper>;
};

const PageContext: React.FC<{ node: any }> = ({ node }) => {
  const setPage = usePageStore(page => page.setPage);

  setPage({
    species: toMachineName(node?.relationships?.species?.name) as 'dog' | 'cat' | undefined,
    brand:
      toMachineName(node?.relationships?.brand?.relationships?.top_parent?.name) ??
      toMachineName(node?.relationships?.brand?.relationships?.parent?.[0]?.name) ??
      toMachineName(node?.relationships?.brand?.name),
  });

  return null;
};

export const WrapPageWithProvider: (args: WrapPageElementBrowserArgs) => React.ReactNode = ({
  element,
  ...rest
}) => {
  const node = rest.props?.data?.node as any;
  const hideFooter = node?.behaviors?.page_layout?.elements_to_hide?.footer;

  return (
    <>
      <PageContext node={node} />
      {hideFooter && <OneTrustButton />}
      <LaunchDarklyFlags />
      {element}
    </>
  );
};
